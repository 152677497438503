(function () {
    'use strict';

    var MODES = {
        VIEW: 0,
        ADD: 1,
        EDIT: 2,
        ADDPROJECT: 3,
        EDITPROJECT: 4
    };

    var modeTitles = {
        0: "View",
        1: "Create",
        2: "Edit",
        3: "Create Project",
        4: "Edit Project"
    };

    var TOOL_TYPES = ['OTDR'];

    angular.module('aerosApp').factory('AddEditToolLibModal', AddEditToolLibModal).run(listenEvents);

    listenEvents.$inject = ['AddEditToolLibModal', 'appDispatcher', "TestSetupFiberModeModal"];

    function listenEvents(AddEditToolLibModal, appDispatcher, TestSetupFiberModeModal) {
        appDispatcher.on('addToolLibrary', handle(MODES.ADD));
        appDispatcher.on('editToolLibrary', handle(MODES.EDIT));
        appDispatcher.on('viewToolLibrary', handle(MODES.VIEW));

        function handle(mode) {
            return function (e, model) {
                if (model.toolType === 'OTDR' && mode === 1 && !model.name) {
                    TestSetupFiberModeModal.open(model, mode).then(function () {
                        AddEditToolLibModal.open(model, mode)
                    });
                } else {
                    ~TOOL_TYPES.indexOf(model.toolType) && AddEditToolLibModal.open(model, mode);
                }
            }
        }
    }

    AddEditToolLibModal.$inject = ['$uibModal', 'ToolsDefinitionService', 'PresetService', '$state', 'aerosApi'];

    function AddEditToolLibModal($uibModal, ToolsDefinitionService, PresetService, $state, aerosApi) {
        return {
            open: open,
            modes: MODES
        };

        function open(toolModel, mode, projectID) {
            var presetService = PresetService(toolModel.toolType),
                modal = $uibModal.open({
                    size: "md",
                    templateUrl: "/static/templates/tools/AddEditToolModal.html",
                    controller: AddEditToolLibModalController,
                    resolve: {
                        toolModel: function () {
                            return ToolsDefinitionService.format.nested2flat(toolModel);
                        },
                        toolDescription: function () {
                            return ToolsDefinitionService.fetch().then(function (d) {
                                return d[toolModel.toolType];
                            });
                        },
                        presets: function () {
                            return presetService.fetch($state.params.id).then(function (presets) {
                                return presets[toolModel.toolType];
                            });
                        },
                        mode: function () {
                            return mode === undefined ? MODES[toolModel.mode] : mode;
                        },
                        organizationID: function () {
                            return $state.params.id;
                        },
                        projectID: function () {
                            return projectID;
                        },
                        presetService: function () {
                            return presetService;
                        },
                        tools: function () {
                            return aerosApi.getOrgToolsByType({
                                orgId: $state.params.id,
                                toolType: toolModel.toolType.toLowerCase()
                            })
                        }

                    }
                });

            return modal.result;
        }
    }

    AddEditToolLibModalController.$inject = [
        '$scope', '$uibModalInstance', 'Notification', 'ToolService', 'toolModel', 'toolDescription',
        'presets', 'mode', 'tools', 'organizationID', 'projectID', 'ToolLibFactory', 'presetService', '$rootScope',
        'ToolLibraryService', 'UserProfileService', 'ToolsDefinitionService', 'UnitsMeasurementService'];

    function AddEditToolLibModalController($scope, $uibModalInstance, Notification, ToolService, toolModel, toolDescription,
                                           presets, mode, tools, organizationID, projectID, ToolLibFactory, presetService,
                                           $rootScope, ToolLibraryService, UserProfileService, ToolsDefinitionService, UnitsMeasurementService) {
        angular.extend($scope, {
            setFromPreset: setFromPreset,
            savePreset: savePreset,
            showCurrentTab: showCurrentTab,
            fieldChangeEventHandler: fieldChangeEventHandler,
            propertyChangeEventHandler: propertyChangeEventHandler,
            saveToolLibrary: saveToolLibrary,
            isVisibleItem: isVisibleItem,
            findDescriptionByName: ToolsDefinitionService.findDescriptionByName
        });

        function setUnitFields() {
            var units = {
                Meters: {
                    name: "meters"
                },
                Kilometers: {
                    name: "meters"
                },
                Miles: {
                    name: "miles",
                    toMeters: UnitsMeasurementService.milesToMeters,
                    fromMeters: UnitsMeasurementService.metersToMiles
                },
                Kilofeet: {
                    name: "feet",
                    toMeters: UnitsMeasurementService.feetToMeters,
                    fromMeters: UnitsMeasurementService.metersToFeet
                },
                Feet: {
                    name: "feet",
                    toMeters: UnitsMeasurementService.feetToMeters,
                    fromMeters: UnitsMeasurementService.metersToFeet
                }

            };

            var userProfile = UserProfileService.get();

            $scope.toolModel.units = units[userProfile.user.preferredUnitOfMeasure].name;
            $scope.toolModel.lossDistanceUnit = userProfile.user.preferredLossDistanceUnit;
            $scope.units = units[userProfile.user.preferredUnitOfMeasure];
        }

        (function init() {


            $scope.testModel = {};
            $scope.toolModel = generateModel(toolModel);
            $scope.toolDescription = toolDescription;
            $scope.presets = presets;
            $scope.mode = mode;
            $scope.modalTitle = (toolDescription.name === 'OTDR Tool') ? modeTitles[mode] + " " + toolDescription.name : "Create " + toolDescription.name;
            $scope.generic = getGeneric(tools.data.tools);
            // Don't propogate the generic name into the form
            $scope.generic.Singlemode.name = '';
            $scope.generic.Multimode.name = '';
            $scope.saveAsPresetOpened = [];
            $scope.selectedPreset = {};
            $scope.showTab = {};
            $scope.showTab[$scope.toolDescription.groups[0].id] = $scope.toolDescription.groups[0];
            $scope.newPresetsName = [];
            $scope.fields = [];

            setUnitFields();

            $scope.modes = MODES;

        })();

        function getGeneric(tools) {
            var generic = {};

            angular.forEach(tools, function (tool) {
                if (tool.generic) {
                    generic[tool.fiberMode] = ToolsDefinitionService.format.nested2flat(tool)
                }
            });

            return generic;
        }

        function generateModel(model) {
            return model.uuid ? model :
                _.chain(toolDescription.groups).map('id').reduce(function (res, key) {
                    return (res[key] = {}, res);
                }, {}).assign(model).value();
        }

        function setFromPreset(selected, key) {
            if (selected) {
                var obj = angular.fromJson(selected);

                if (!$scope.toolModel[key]) {
                    $scope.toolModel[key] = {};
                }

                for (var field in obj.fields) {
                    $scope.toolModel[key][field] = obj.fields[field];
                }
            }
        }

        function showCurrentTab(group) {
            $scope.showTab = $scope.showTab || {};

            $scope.showTab[group.id] = $scope.showTab[group.id] === group ?
                undefined : group;
        }

        function savePreset(group) {
            var newPreset = {
                name: $scope.newPresetsName[group.id],
                type: group.id,
                fields: {}
            };
            for (var groupItem in group.fields) {
                newPreset.fields[group.fields[groupItem].fieldName] = $scope.toolModel[group.id + '.' + group.fields[groupItem].fieldName];
            }
            newPreset.fields.fiberMode = $scope.toolModel.fiberMode;


            presetService.create(organizationID, newPreset).then(function () {
                presetService.fetch(organizationID).then(function (presets) {
                    $scope.presets = presets[toolModel.toolType];

                   var newPresetSaved = $scope.presets[group.id]
                        .find(function (preset) {
                            return preset.name === newPreset.name;
                        });

                    var firstPreset = $scope.presets[group.id][0];
                    var index = $scope.presets[group.id].indexOf(newPresetSaved) ;
                    $scope.presets[group.id][index] = firstPreset ;
                    $scope.presets[group.id][0] = newPresetSaved ;

                    $scope.newPresetsName[group.id] = '';
                    $scope.selectedPreset[group.id] = angular.toJson(newPresetSaved);
                    $scope.saveAsPresetOpened[group.id] = false;

                    $scope.toolModel[group.id+".name"] = newPresetSaved.name;

                    Notification.success('Preset saved successfully');
                });

            }).catch(function (err) {
                Notification.error(err.data.message + ': ' + err.data.detail);
            });
        }

        function fieldChangeEventHandler(fields, presets, group) {
            var found = presets[group.id].find(function (preset) {
                return ToolService.compareModelFieldWithPreset(fields, preset.fields);
            });

            //$scope.selectedPreset[group.id] = found ?
            // angular.toJson(found) : '';
            $scope.selectedPreset[group.id] = found ?
            angular.toJson(preset) : '';
        }

        function propertyChangeEventHandler(properties) {
            // The first parameter property.fieldName produced exception
            // because properties is undefiled. This is not directly related to bug 3498
            var children = ToolsDefinitionService.findChildren($scope.fieldName, toolDescription);

            if (children.length > 0) {
                angular.forEach(children, function (child) {
                    if (child.fieldDescription.findNearestOnParentChange) {
                        ToolsDefinitionService.updateModelWithNearestValue(child, properties.fieldName, $scope.toolModel,
                            properties.fieldDescription);
                    }
                });
            }
        }

        function updateWithUUID() {
            $scope.toolModel.uuid = "" + Math.random();

            angular.forEach(toolDescription.groups, function (value, key) {
                if ($scope.toolModel[value.id]) {
                    $scope.toolModel[value.id].uuid = "" + Math.random();
                    $scope.toolModel[value.id].name = $scope.selectedPreset[value.id] &&
                        angular.fromJson($scope.selectedPreset[value.id]).name;
                } else {
                    $scope.toolModel[value.id] = $scope.toolModel[value.id] || {};
                    $scope.toolModel[value.id].uuid = "" + Math.random();
                    $scope.toolModel[value.id].name = undefined;
                }
            });
        }

        function saveToolLibrary() {

            var toolModel = angular.copy($scope.toolModel);

            function commonErrorHandler(error) {
                if (error && error.data && error.data.message) {
                    Notification.error(error.data.message + ": " + error.data.detail);
                } else if (error && error.data) {
                    Notification.error(error.data);
                }
            }

            switch ($scope.mode) {
                case MODES.ADD :
                    updateWithUUID();

                    ToolLibraryService.create($scope.toolDescription.type, organizationID, toolModel)
                        .then(function (results) {
                            $rootScope.$broadcast('toolLibListUpdate');
                            $uibModalInstance.close($scope.toolModel);
                        }, commonErrorHandler);
                    break;
                case MODES.EDIT:
                    ToolLibraryService.update($scope.toolDescription.type, organizationID, toolModel)
                        .then(function (results) {
                            Notification.success("Tool Library updated successfully!");
                            $rootScope.$broadcast('toolLibListUpdate');
                            $uibModalInstance.close($scope.toolModel);
                        }, commonErrorHandler);
                    break;
                case MODES.ADDPROJECT:
                    ToolService.addTool($scope.toolDescription.type, organizationID, projectID, {
                        tool: toolModel
                    }).then(function (results) {
                        $uibModalInstance.close($scope.toolModel);
                    }, commonErrorHandler);
                    break;
                case MODES.EDITPROJECT:
                    ToolService.updateTool($scope.toolDescription.type, organizationID, projectID, {
                        tool: toolModel,
                        overwrite: false
                    }).then(function (results) {
                        $uibModalInstance.close(results);
                    }, commonErrorHandler);
                    break;
            }
            // toolLibraries should be an array, so we will pass array of one
            var toolLibraries = [$scope.toolModel];
            ToolService.presetMatch(ToolService, $scope.toolDescription, toolLibraries, $scope.presets);
        }

        function createToolLibrary() {
            updateWithUUID();
            return ToolLibFactory(toolModel.toolType).create({orgId: organizationID}, $scope.toolModel).$promise.then(function (results) {
                $rootScope.$broadcast('toolLibraryCreateSuccess');
                $uibModalInstance.close();
                Notification.success("tool library added successfully");
            }).catch(function () {
                Notification.error("tool library adding failed");
                $rootScope.$broadcast('toolLibraryCreateError');
            });
        }

        function updateToolLibrary() {
            return toolModel.update()
                .then(function (results) {
                    $uibModalInstance.close();
                    $rootScope.$broadcast('toolLibraryUpdateSuccess');
                    Notification.success("tool library updated successfully");
                }).catch(function () {
                    Notification.error("tool library update failed");
                    $rootScope.$broadcast('toolLibraryUpdateError');
                });
        }

        function isVisibleItem(description, toolModel) {
            return description['type'] === 'hidden' ? false : !description.parent ||
                (description.parent && description.values[toolModel[description.parent]])
                || (description.parent_value
                && description.values[toolModel[description.parent][description.parent_value]]);
        }
    }
})();
